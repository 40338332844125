.rpb-root {
  display: inline-flex;
  flex-direction: column;
}

.rpb-root ul {
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rpb-item {
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 0.375rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  font-size: 1rem;
  height: 2.4rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  min-width: 2.4rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  position: relative;
  transition: all 0.15s ease;
  user-select: none;
  white-space: nowrap;
  width: auto;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rpb-item--disabled {
  opacity: 0.4;
}

.rpb-item--active {
  background-color: rgb(76, 120, 255);
  color: #fff;
}

.rpb-progress {
  width: 0%;
  height: 3px;
  background-color: #7597ff;
  margin: 1rem 0;
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 0 0px 4px rgba(127, 177, 255, 0.6);
  transition: width 200ms ease;
}

.rpb-go-item {
  -webkit-padding-end: 0.75rem;
  -webkit-padding-start: 0.75rem;
  align-items: center;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.04);
  display: flex;
  height: 2.4rem;
  padding-inline-end: 0.75rem;
  padding-inline-start: 0.75rem;
  width: 70px;
  font-size: 1rem;
}
.rpb-go-item input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 20px;
  width: 100%;
}
